import React from "react"
// import Img from "gatsby-image"
// import { useStaticQuery, graphql } from "gatsby"
import style from "./contact.module.css"

// import classNames from "classnames/bind"
// const cx = classNames.bind(style)



export default function Contact() {

    return (
        <>
            <div className={style.contact}>
                <div className={style.contactText}>
                    mail to info@samyak-design.com | whatsapp to 94848 00028
                </div>
            </div>
        </>
    )
}


