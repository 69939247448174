import React from "react"
// import Img from "gatsby-image"
// import { useStaticQuery, graphql } from "gatsby"
import style from "./location.module.css"

// import classNames from "classnames/bind"
// const cx = classNames.bind(style)

export default function Location() {
    // const data = useStaticQuery(
    //     graphql`
    //       query {
    //         #
    //         vaibhavaImage: file(relativePath: { eq: "people/vaibhava-image.png" }) {
    //           ...defaultImageTreatment
    //         }
    //         #
    //       }
    //     `
    // )
    return (
        <>
            <div className={style.location}>

                <iframe
                    className={style.mapEmbedded}
                    title="Address: Wework Salarpuria Symbiosis"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28015.354041469043!2d77.09554639207066!3d28.63218200158776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03252d5a9a7d%3A0x5f7e526d5e6be39f!2sSamyak%20Design%20Consultants%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1578287956371!5m2!1sen!2sin"
                ></iframe>
            </div>
        </>
    )
}


