import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import style from "./team.module.css"

// import classNames from "classnames/bind"
// const cx = classNames.bind(style)

export default function Team() {
    const data = useStaticQuery(
        graphql`
          query {
            vaibhavaImage: file(relativePath: { eq: "people/deepak_verma.jpg" }) {
                ...defaultImageTreatment
            }
            #
            deepak_verma: file(relativePath: { eq: "people/deepak_verma.jpg" }) {
                ...defaultImageTreatment
            }
            #
            priya_verma: file(relativePath: { eq: "people/priya_verma.jpg" }) {
                ...defaultImageTreatment
            }
            #
            arshi_barwad: file(relativePath: { eq: "people/arshi_barwad.jpg" }) {
                ...defaultImageTreatment
            }
            #
            maneesh_bathla: file(relativePath: { eq: "people/maneesh_bathla.jpg" }) {
                ...defaultImageTreatment
            }
            #
            pooja: file(relativePath: { eq: "people/pooja.jpg" }) {
                ...defaultImageTreatment
            }
            #
            khushboo: file(relativePath: { eq: "people/khushboo.jpg" }) {
                ...defaultImageTreatment
            }
            #
            raju_kumar: file(relativePath: { eq: "people/raju_kumar.jpg" }) {
                ...defaultImageTreatment
            }
            #
          }
        `
    )
    return (
        <>
            <div className={style.team}>
                <div className={style.people}>
                    <div className={style.header}>Team</div>
                    <div className={style.peopleRow}>
                        <div className={style.professional}>
                            <Img
                                className={style.portrait}
                                fluid={data.deepak_verma.childImageSharp.fluid}
                                alt="deepak_verma.jpg"
                            />
                            <div className={style.name}>Deepak Verma</div>
                            <div className={style.designation}>Architect</div>
                            <div className={style.description}>
                                <p>
                                    A passionate architectural graduate, Deepak started his career in early 2003. He gained international exposure with projects and firms he worked with. His inclination towards holy script of Bhagvadgeeta has helped him continuously evolve into a better human being and a designer. He strongly believes - that God is The Ultimate designer and we need to tap from his infinite source of energy and provide the same to humanity through the tool – Design. That design tool can enhance the quality life of people, can uplift their self being, their growth and productivity. The design can make people believe in goodness & honesty. His integral philosophical approach soon pushed him to start his new venture – SAMYAK in 2010.
                                </p>
                                <p>
                                    Earlier he worked with the multinational design firm – Space Matrix as a Sr. Designer. During his tenure he handled various international clients. He was key account manager for their most valuable client – The Royal Bank of Scotland for almost 4 years. His responsibilities involved end to end solution, design and detailing, cost and execution. As founder his approach permeates through the Samyak, by understanding the customer’s requirements, their psychology, culture, and interests at microscopic level and then translating them into intuitive, functional, productive, creatively designed & harmoniously balanced environments.
                                </p>
                            </div>
                        </div>
                        <div className={style.professional}>
                            <Img
                                className={style.portrait}
                                fluid={data.priya_verma.childImageSharp.fluid}
                                alt="priya_verma.jpg"
                            />
                            <div className={style.name}>Priya Verma</div>
                            <div className={style.designation}>Architect</div>
                            <div className={style.description}>
                                <p>
                                    Inspired by her architect uncle, Priya had childhood dreams of becoming an architect. Her inherent design abilities started being visible through her matured approach from early college days. She graduated from MITS Gwalior in 2003. Since then she never stopped expressing her design vigour. She was so much passionate that she left her secured & lucrative government post of an architect (a Gazetted officer) to satisfy her own creative hunger. She became the co-founder of ‘Samyak’ in 2010. She strongly believes in the divine relationship between Design and Spirituality.
                                </p>
                                <p>
                                    She is responsible to handle all the major design assignments of the company and ensures to maintain the purity and originality in the design processes of each project. She is very hardworking, dedicated and honest leader towards her design approach. Her craving for freshness in design reflects in all her respective assignments. She is an eternal source of inspiration to the whole design team.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={style.staffRow}>
                        <div className={style.professionalStaff}>
                            <Img
                                className={style.portraitStaff}
                                fluid={data.maneesh_bathla.childImageSharp.fluid}
                                alt="maneesh_bathla.jpg"
                            />
                            <div className={style.professionalStaffText}>
                                <div className={style.nameStaff}>Manish Bathla</div>
                                <div className={style.designationStaff}>Construction Lead</div>
                            </div>
                        </div>
                        <div className={style.professionalStaff}>
                            <Img
                                className={style.portraitStaff}
                                fluid={data.pooja.childImageSharp.fluid}
                                alt="pooja.png"
                            />
                            <div className={style.professionalStaffText}>
                                <div className={style.nameStaff}>Pooja Rustagi</div>
                                <div className={style.designationStaff}>Interior Designer</div>
                            </div>
                        </div>
                        <div className={style.professionalStaff}>
                            <Img
                                className={style.portraitStaff}
                                fluid={data.arshi_barwad.childImageSharp.fluid}
                                alt="arshi_barwad.jpg"
                            />
                            <div className={style.professionalStaffText}>
                                <div className={style.nameStaff}>Arshi Barwad</div>
                                <div className={style.designationStaff}>Trainee Architect</div>
                            </div>
                        </div>


                        <div className={style.professionalStaff}>
                            <Img
                                className={style.portraitStaff}
                                fluid={data.khushboo.childImageSharp.fluid}
                                alt="khushboo.png"
                            />
                            <div className={style.professionalStaffText}>
                                <div className={style.nameStaff}>Khushboo</div>
                                <div className={style.designationStaff}>HR Head</div>
                            </div>
                        </div>
                        <div className={style.professionalStaff}>
                            <Img
                                className={style.portraitStaff}
                                fluid={data.raju_kumar.childImageSharp.fluid}
                                alt="raju_kumar.png"
                            />
                            <div className={style.professionalStaffText}>
                                <div className={style.nameStaff}>Raju Kumar</div>
                                <div className={style.designationStaff}>Site Engineer</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}


