import React from "react"

import style from "./identity.module.css"
import wordmark_orange from '../../images/brand/wordmark_orange.svg';

// import classNames from "classnames/bind"
// const cx = classNames.bind(style)

export default function Identity() {

    return (
        <>
            <div className={style.identity}>

                <img className={style.wordmark} src={wordmark_orange} alt="wordmark.svg" />
                <div className={style.tagline}>
                    spaces for growth
                </div>

            </div>

        </>
    )
}


